@import '../pages/RWD';

%gototop_png {
  background-image: url('/../public/assets/gotop.png');
  will-change: transform, background;
  content: "";
  width: 80px;
  height: 80px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.themeBorderBottom {
  border-bottom: 2px solid #39B6FF !important;
}


.themeBorderTop {
  border-top: 2px solid #39B6FF !important;
}


.logo {
  height: 25px;
}

.abs {

}

.actionButton {
  --color: black;
  --padding-start: 0;
  --padding-end: 0;
  height: auto;
}

.activeButton_InFavor {
  --color: red;
}

.actionButtonIcon {
  display: inline-block;

  //color:red;
  width: 30px;
  height: 30px;
}

.userNameBlueIcon {
  display: inline-block;
  color: #39B6FF;
  width: 1em;
  height: 1em;
  margin-left: 5px;
}

.defaultAvatarBgColor {
  fill: #a2a2a2;
  color: #a2a2a2;
  width: 100%;
  height: 100%;
}


.ionContent {
  //@include media("<tablet") { /* is phone */
  //}
  //
  //@include media(">=tablet", "<desktop") { /* is tablet */
  //}
  //
  //@include media(">=desktop", "<xdesktop") { /* is desktop */
  //    --offset-top: 0;
  //    --offset-bottom: 0;
  //    overflow-y: scroll;
  //}
  //
  //@include media(">=xdesktop") { /* is xdesktop */
  //    --offset-top: 0;
  //    --offset-bottom: 0;
  //    overflow-y: scroll;
  //}
}

.gototop {
  background-color: rgba(0, 0, 0, 0.3);
  //right: 40px;
  //bottom: 40px;
  position: fixed;
  z-index: 1000;
  cursor: pointer;
  border-radius: 7px;
  padding: 0;
  margin: 0;
  transition: opacity 1s ease-in-out;
  border: none;
  outline: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  :global(.gototopBody) {
    @extend %gototop_png;
  }

  right: 8px;
  bottom: 30px;

  :global(.gototopBody) {
    width: 30px;
    height: 30px;
  }

  //@include media("<tablet") { /* is phone */
  //  right: 8px;
  //  bottom: 30px;
  //
  //  :global(.gototopBody) {
  //    width: 30px;
  //    height: 30px;
  //  }
  //}
  //
  //@include media(">=tablet", "<desktop") { /* is tablet */
  //  right: 8px;
  //  bottom: 0px;
  //}
  //
  //@include media(">=desktop", "<xdesktop") { /* is desktop */
  //  right: 48px;
  //  bottom: 8px;
  //}
  //
  //@include media(">=xdesktop") { /* is xdesktop */
  //  right: 48px;
  //  bottom: 8px;
  //}
}
