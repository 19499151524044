@import '../pages/RWD';

.container {
  width: 100%;
}

.containerInner {
  display: flex;
  flex-flow: column nowrap;

  border-radius: 25px;
  border: 0.5px solid #d3d3d3;
  padding: 16px;
  margin-bottom: 16px;
  color: black;

  box-shadow: 1px 1px 5px #b5b5b5;
  overflow: visible;
  margin-left: 5px;
  width: calc(100% - 10px);

  &:first-child {
    margin-top: 8px;
  }
}

.titleBar {
  font-size: 24px;
  //margin: 16px 0;
  //padding: 0 40px;
  padding: 0;
}

.contentBar {
  margin: 16px 0;

  :global(.l) {
    font-size: 24px;
    margin: 16px 0;
  }

}

.qrBar {
  margin: 16px 0;

  :global(.l) {
    font-size: 24px;
    margin: 16px 0;
  }
  :global(.c) {
    text-align: center;
  }
}

.timeRangeBar {
  :global(.l) {
    font-size: 24px;
    margin: 16px 0;
  }
}

.redeemBar {
  display: inline-block;
  text-align: center;

  :global(.l) {
    font-size: 24px;
    margin: 16px 0;
  }
}

.postBar {
  margin-top: 8px;

  //display: flex;
  //justify-content: flex-start;
  //align-items: center;
  //flex-flow: row nowrap;

  display: flex;
  overflow: hidden;
  width: 100%;

  :global(>.l) {
    flex: 1 0 33%;
    max-width: 33%;


  }



  :global(>.r) {
    flex-basis: 100%;
    text-align: center;
    //border: 1px dotted gray;

    //flex: 1 1;
    //height: 100%;
    //display: flex;
    //justify-content: flex-start;
    //align-items: flex-start;
    //flex-flow: column nowrap;

    margin-left: 24px;

    :global(>.rInner) {
      height: 100%;

      padding-right: 16px;

      display: flex;
      justify-content: center;
      flex-flow: column nowrap;
    }
  }

  :global(>img) {
    width: 100%;
    height: 100%;

    object-fit: contain;
    display: block;
    border-radius: 8px;

    @include media("<tablet") { /* is phone */
    }

    @include media(">=tablet", "<desktop") { /* is tablet */
    }

    @include media(">=desktop", "<xdesktop") { /* is desktop */
      max-height: calc(50vh);
    }

    @include media(">=xdesktop") { /* is xdesktop */
      max-height: calc(50vh);
    }
  }
}

.actionButton {
  --padding-start: 0;
  --padding-end: 0;
}

.actionBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;


  :global(>.actions) {
    display: inline-block;
  }

  :global(>.labels) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row nowrap;
    flex: 1;
  }

  :global(.label-redeem) {
    background-color: orange;
    color: white;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #b5b5b5;
  }

  :global(.label-redeemed) {
    background-color: orange;
    color: white;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #b5b5b5;
  }
}

.textBar {
  margin-top: 8px;
  font-size: 1.2em;

  overflow-wrap: anywhere;
  white-space: pre-wrap;
  word-wrap: normal;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 6;

  flex: 1;
}

.timeRangeBar {
  margin-top: 8px;
  font-size: 1.2em;
}

.nameBar {
  font-size: 1em;

  :global(.avatar) {
    display: inline-block;

    width: 30px;
    height: 30px;
    //width: 40%;
    //height: 40%;
    //max-width: 200px;
    //max-height: 200px;
  }

  :global(.name) {
    display: inline-block;
  }

  :global(.container) {
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //flex-flow: row nowrap;

    :global(.l) {

    }

    :global(.r) {
      flex: 1;
      margin-left: 8px;

      overflow-wrap: anywhere;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    :global(.r2) {
      float: right;

      margin-left: 8px;
      overflow-wrap: anywhere;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1em;
      height: 1em;

      :global(.roundbg) {


      }
    }
  }
}

.coverImageContainer {
  @include media("<tablet") { /* is phone */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 0.8rem;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
    width: 100%;
  }

  @include media(">=tablet", "<desktop") { /* is tablet */

    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;

  }

  @include media(">=desktop", "<xdesktop") { /* is desktop */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;
  }

  @include media(">=xdesktop") { /* is xdesktop */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;
  }
}
