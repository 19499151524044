.h50px {
  overflow: hidden;
  object-fit: cover;
  height: 50px;
  width: auto;


  :global(img) {
    height: 100%;
    width: 100%;
  }
}


.maxHeight {
  max-height: 200px;
  overflow-y: auto;
}


.multipleImgs {
  img {
    margin-left: 20px;
    width: 50px;
    height: 50px;
    height: auto;
    max-height: 50px;
  }
  &:first-child {
    margin-left: 0;
  }
}
